import {
  PARTY,
  JURISDICTION,
  QS,
  ENDORSEMENT,
  ALPHABET,
  ENDORSEMENT_ORDER,
} from '../components/election/constants.js';
import { Contest, Candidate, Judge, Measure } from '../components/election/data_objs.js';

const defaultOrder = ALPHABET;
// Name ordering for November 2024 state contests
// https://www.sos.ca.gov/administration/news-releases-and-advisories/2024-news-releases-and-advisories/secretary-state-shirley-n-weber-phd-announces-results-randomized-alphabet-drawing-november-5-2024-general-election
const stateOrder = 'IFLTDEHQMJGAYRPCBVXKUNOWSZ';
// Name ordering for November 2024 local contests
// https://www.youtube.com/watch?v=gkvT9VK3OJE
const localOrder = 'NXQWGMBSVFORCKYLEUATDIJPHZ';

// These are listed in the order in which they'll appear on the ballot
export const nov24_contests = {
  // https://elections.cdn.sos.ca.gov//statewide-elections/2024-primary/cert-list-candidates.pdf
  President: Contest(
    JURISDICTION.FEDERAL,
    'President',
    [
      Candidate(
        'Joseph R Biden Jr',
        PARTY.DEM,
        'Incumbent President of the United States',
        QS.NOT_SENT
      ),
      Candidate('Donald J. Trump', PARTY.REP, 'Former President of the United States', QS.NOT_SENT),
      Candidate('Chase Oliver', PARTY.LIB, '', QS.NOT_SENT),
      Candidate('Robert F. Kennedy Jr.', PARTY.IND, '', QS.NOT_SENT),
      Candidate('Marianne Williamson', PARTY.DEM, '', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  'US Congress, District 11': Contest(
    JURISDICTION.FEDERAL,
    'US Congress, District 11',
    [
      Candidate(
        { en: 'Nancy Pelosi', zh: '南希 ‧ 佩洛西' },
        PARTY.DEM,
        'Member of Congress; Incumbent',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Bruce Lou', zh: '樓成生' }, PARTY.REP, 'Business Owner', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  'US Congress, District 15': Contest(
    JURISDICTION.FEDERAL,
    'US Congress, District 15',
    [
      Candidate(
        { en: 'Kevin Mullin', zh: '凱文 穆林' },
        PARTY.DEM,
        'U.S. Representative; Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Anna Cheng Kramer', zh: '陳安娜' },
        PARTY.REP,
        'Housing Policy Advisor',
        QS.NOT_SENT
      ),
    ],
    defaultOrder
  ),
  'State Senate, District 11': Contest(
    JURISDICTION.STATE,
    { en: 'State Senate, District 11', zh: '州參議院，第 11 區' },
    [
      Candidate(
        { en: 'Scott Wiener', zh: '威善高' },
        PARTY.DEM,
        'State Senator; Incumbent',
        'https://drive.google.com/file/d/1CvHCB0xQI2li6s8C88lElthbesfLAOgt/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Yvette Corkrean', zh: '依薇特·科克安' },
        PARTY.REP,
        'Registered Nurse / Mother',
        'https://drive.google.com/file/d/1OLBQy3yFBnGanch1Ccv7AQ_EP8Qh_O9e/view?usp=drive_link'
      ),
    ],
    defaultOrder
  ),
  'State Assembly, District 17': Contest(
    JURISDICTION.STATE,
    { en: 'State Assembly, District 17', zh: ' 17 區州眾議院，第17區' },
    [
      Candidate(
        { en: 'Matt Haney', zh: '楊馳馬' },
        PARTY.DEM,
        'Assemblymember; Incumbent',
        'https://drive.google.com/file/d/1ca76TqSGsVg_SCFo-tSx-XSIWJHfBEgy/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Manuel Noris-Barrera', zh: '李明杰' },
        PARTY.REP,
        'Real Estate Businessman',
        QS.NO_RESPONSE
      ),
    ],
    defaultOrder
  ),
  'State Assembly, District 19': Contest(
    JURISDICTION.STATE,
    { en: 'State Assembly, District 19', zh: ' 19 區州眾議院，第17區' },
    [
      Candidate(
        { en: 'Catherine Stefani', zh: '司嘉怡' },
        PARTY.DEM,
        'Supervisor City and County of San Francisco, District 2',
        'https://drive.google.com/file/d/13nLy5Ldf47U8pz0r9BFAhuSAYCIZI5ab/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'David E. Lee', zh: '李志威' },
        PARTY.DEM,
        'Civil Rights Educator',
        QS.NO_RESPONSE
      ),
    ],
    defaultOrder
  ),
  'Board of Education': Contest(
    JURISDICTION.LOCAL,
    {
      en: 'Board of Education',
      zh: '教育委員會成員',
      es: 'Meimbro, Consejo de Educación',
      fil: 'Miyembro, Lupon ng Edukasyon',
    },
    [
      Candidate(
        { en: 'Matt Alexander', zh: '艾善德' },
        PARTY.NP,
        {
          en: 'Board Vice President',
          zh: '委員會副主席',
          es: 'Vicepresidente de la junta directiva',
          fil: 'Bise Presidente ng Lupon',
        },
        'https://drive.google.com/file/d/1B7G9VvizODYoRKukr4ez0Zm28fWSXeRL/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Min Chang', zh: '張珉' },
        PARTY.NP,
        {
          en: 'Chief Executive Officer',
          zh: '首席執行官',
          es: 'Directora ejecutiva',
          fil: 'Punong Tagapangasiwang Opisyal',
        },
        'https://drive.google.com/file/d/1W259XjQxEPWGVzE6GhLkc4Maxnfk-dRr/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Virginia Cheung', zh: '張麗娜' },
        PARTY.NP,
        {
          en: 'Nonprofit Educator / Mother',
          zh: '非營利教育工作者 / 母親',
          es: 'Educadora sin fines de lucro / Madre',
          fil: 'Edukador sa Nonprofit / Ina',
        },
        'https://drive.google.com/file/d/15ygI6l6cRYXOMOSAUFsAkoBiUBCnY9uM/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Lefteris Eleftheriou', zh: '李夫特' },
        PARTY.NP,
        {
          en: 'Engineer / School Owner',
          zh: '工程師 / 學校營辦人',
          es: 'Ingeniero / Propietario de escuela',
          fil: 'Inhinyero / May-ari ng Paaralan',
        },
        'https://drive.google.com/file/d/18xUSyMACYgkVGmrhCSD8o4T5t9Hnefp8/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Parag Gupta', zh: '帕拉格 ‧ 古普塔' },
        PARTY.NP,
        {
          en: 'Affordable Housing Director',
          zh: '可負擔房屋總監',
          es: 'Director de vivienda asequible',
          fil: 'Direktor ng Abot-kayang Pabahay',
        },
        'https://drive.google.com/file/d/1yd6W-LItPnMNKEiD9HexGyfFUYzJPw2G/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Ann Hsu', zh: '徐安' },
        PARTY.NP,
        {
          en: 'School Principal',
          zh: '學校校長',
          es: 'Directora de escuela',
          fil: 'Punong-guro ng Paaralan',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jaime Huling', zh: '海梅 ‧ 胡玲' },
        PARTY.NP,
        {
          en: 'Deputy City Attorney',
          zh: '副市府律師',
          es: 'Abogada de la Ciudad Adjunta',
          fil: 'Katuwang ng Abugado ng Lungsod',
        },
        'https://drive.google.com/file/d/1YumPlYLvylUBRLP9E5bqz42R2c9zPzh2/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'John Jersin', zh: '鄭弘杰' },
        PARTY.NP,
        {
          en: 'Education Non-profit Director',
          zh: '非營利教育機構總監',
          es: 'Director de organización educativa sin fines de lucro',
          fil: 'Direktor ng Pang-edukasyong Non-profit',
        },
        'https://drive.google.com/file/d/18xaUki9Bfm_NH2RIBanPuyqMdPFXgTNr/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Maddy Krantz', zh: '麥迪 ‧ 克蘭茨' },
        PARTY.NP,
        {
          en: 'College Student',
          zh: '大學生',
          es: 'Estudiante universitaria',
          fil: 'Estudyante sa Kolehiyo',
        },
        QS.NO_CONTACT
      ),
      Candidate(
        { en: 'Laurance Lem Lee', zh: '林灼世' },
        PARTY.NP,
        {
          en: 'Small Business Owner',
          zh: '小企業主',
          es: 'Dueño de pequeño negocio',
          fil: 'May-ari ng Maliit na Negosyo',
        },
        'https://drive.google.com/file/d/1pw0R8Kb3AJP773socor6Vx8Kmvsq2jzp/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Supryia Ray', zh: '蘇普里亞 ‧ 雷' },
        PARTY.NP,
        {
          en: 'Attorney / Parent Organizer',
          zh: '律師 / 家長組織者',
          es: 'Abogada / Organizadora de padres',
          fil: 'Abugado / Tagapag-organisa ng mga Magulang',
        },
        'https://drive.google.com/file/d/1ESIFdcKzOv-NXkASt-w3627prJvMQTRE/view?usp=drive_link',
        true
      ),
    ],
    defaultOrder,
    'Vote for all 4'
  ),
  'Community College Board': Contest(
    JURISDICTION.LOCAL,
    'Community College Board',
    [
      Candidate(
        { en: 'Aliya Chisti', zh: '艾麗雅' },
        PARTY.NP,
        {
          en: 'Trustee, City College Board',
          zh: '市立大學董事',
          es: 'Síndica, Consejo del Colegio Comunitario',
          fil: 'Katiwala, Lupon ng Kolehiyo ng Lungsod',
        },
        'https://drive.google.com/file/d/1p6GMQw3rQZBliL3egIms5i4mGRtH0T1J/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Ruth Ferguson', zh: '露芙 ‧ 費格遜' },
        PARTY.NP,
        {
          en: 'Policy Analyst / Organizer',
          zh: '政策分析員 / 組織者',
          es: 'Analista de políticas / Organizadora',
          fil: 'Tagapagsuri ng Polisiya / Organisador',
        },
        'https://drive.google.com/file/d/1FmGj1a533uPqdft89hB-GrRsZzRrgCig/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Ben Kaplan', zh: '班勇杰' },
        PARTY.NP,
        {
          en: 'College Author / Economist',
          zh: '大學作家 / 經濟學者',
          es: 'Autor universitario / Economista',
          fil: 'Awtor sa Kolehiyo / Ekonomista',
        },
        'https://drive.google.com/file/d/1igHkrKzxkPjzL5MRlNockezdRb6Oo95M/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Leanna C. Louie', zh: '雷千紅' },
        PARTY.NP,
        {
          en: 'Parent / Business Owner',
          zh: '家長 / 企業主',
          es: 'Madre / Dueña de negocio',
          fil: 'Magulang / May-ari ng Maliit na Negosyo',
        },
        'https://drive.google.com/file/d/10-bpnpkOwVmlDk6LlgudMV3BQurR0aj8/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Heather McCarty', zh: '希德 ‧ 麥卡蒂' },
        PARTY.NP,
        {
          en: 'Community College Professor',
          zh: '社區大學教授',
          es: 'Profesora de Colegio Comunitario',
          fil: 'Propesor sa Kolehiyo ng Komunidad',
        },
        'https://drive.google.com/file/d/14hSVOzO8ld7HSmHekwovlw_4w5uiYKNv/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Julio J. Ramos', zh: '林波' },
        PARTY.NP,
        {
          en: 'Consumer Rights Lawyer',
          zh: '消費者權益律師',
          es: 'Abogado de derechos del consumidor',
          fil: 'Abugado para sa mga Karapatan ng Mga Mamimili',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Alan Wong', zh: '王兆倫' },
        PARTY.NP,
        {
          en: 'President, College Board',
          zh: '市立大學董事會主席',
          es: 'Presidente, Consejo del Colegio Comunitario',
          fil: 'Presidente, Lupon ng Kolehiyo',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Luis Zamora', zh: '路易斯 ‧ 扎莫拉' },
        PARTY.NP,
        {
          en: 'Community Relations Director',
          zh: '社區關係總監',
          es: 'Director de relaciones comunitarias',
          fil: 'Direktor ng mga Ugnayan sa Komunidad',
        },
        'https://drive.google.com/file/d/1fxOuMU15REeSXqwk2pH-r9_hSFvahlsp/view?usp=drive_link',
        true
      ),
    ],
    defaultOrder,
    'Vote for all 4'
  ),
  'BART Board, District 7': Contest(
    JURISDICTION.LOCAL,
    'BART Board, District 7',
    [
      Candidate(
        'Victor Flores',
        PARTY.NP,
        'Environmental Policy Advisor',
        'https://drive.google.com/file/d/1aEnrMwm1aOc5tN7NqMXrLCCXViUNMsP_/view?usp=drive_link',
        true
      ),
      Candidate(
        'Dana Lang',
        PARTY.NP,
        'Transportation Funding Advisor',
        'https://drive.google.com/file/d/1UHBHnH4VH_tDRAaXeCu__OxsXr7PCgUZ/view?usp=drive_link'
      ),
    ],
    defaultOrder
  ),
  'BART Board, District 9': Contest(
    JURISDICTION.LOCAL,
    'BART Board, District 9',
    [
      Candidate(
        { en: 'Joe Sangirardi', zh: '喬 ‧ 桑吉拉迪' },
        PARTY.NP,
        {
          en: 'Housing Policy Director',
          zh: '房屋政策總監',
          es: 'Director de política de vivienda',
          fil: 'Direktor ng Polisiya sa Pabahay',
        },
        'https://drive.google.com/file/d/1lRwqauzxXuBf8EL6OfCrYSmKjxWnGBYc/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Edward Wright', zh: '王愛德' },
        PARTY.NP,
        {
          en: 'Senior Transit Advisor',
          zh: '資深交通顧問',
          es: 'Asesor sénior de transporte público',
          fil: 'Nakatataas na Tagapayo para sa Pampublikong Transportasyon',
        },
        QS.NO_RESPONSE
      ),
    ],
    defaultOrder
  ),
  Mayor: Contest(
    JURISDICTION.LOCAL,
    {
      en: 'Mayor',
      zh: '市長',
      es: 'Alcalde',
      fil: 'Mayor',
    },
    [
      Candidate(
        { en: 'London Breed', zh: '倫敦 ‧ 布里德' },
        PARTY.DEM,
        {
          en: 'Mayor of San Francisco; Incumbent',
          zh: '三藩市市長',
          es: 'Alcaldesa de San Francisco',
          fil: 'Alkalde ng San Francisco',
        },
        'https://drive.google.com/file/d/19TWzQf4Qccu3Kk4NyUOZHNabNTTkuAxe/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Ellen Lee Zhou', zh: '李愛晨' },
        PARTY.REP,
        {
          en: 'Family Social Worker',
          zh: '家庭社會工作者',
          es: 'Trabajadora social y familiar',
          fil: 'Social Worker para sa mga Pamilya',
        },
        'https://drive.google.com/file/d/1JTFc9fpicSW3EIC5NeZAeFLTKf0SzTyz/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Daniel Lurie', zh: '丹尼爾 ‧ 羅偉' },
        PARTY.DEM,
        {
          en: 'Nonprofit Executive',
          zh: '非營利組織高管',
          es: 'Ejecutivo sin fines de lucro',
          fil: 'Ehekutibo sa Non-profit',
        },
        'https://drive.google.com/file/d/1apBrHGloS9S_qOL6Lr7GGoGAzJv7DTGe/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Mark Farrell', zh: '麥法恩' },
        PARTY.DEM,
        {
          en: 'Small Business Owner; former Mayor of San Francisco',
          zh: '小企業主',
          es: 'Dueño de pequeño negocio',
          fil: 'May-ari ng Maliit na Negosyo',
        },
        'https://drive.google.com/file/d/1DFLiVs5fiSAAL_cBTfCrCjW-QMDPFSIC/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Aaron Peskin', zh: '佩斯金' },
        PARTY.DEM,
        {
          en: 'President, Board of Supervisors; Supervisor, District 3',
          zh: '市議會主席',
          es: 'Presidente, Consejo de Supervisores',
          fil: 'Presidente, Lupon ng mga Superbisor',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Ahsha Safaí', zh: '安世輝' },
        PARTY.DEM,
        {
          en: 'County Supervisor; District 11',
          zh: '縣議員',
          es: 'Supervisor del Condado',
          fil: 'Superbisor ng County',
        },
        QS.NO_RESPONSE
      ),
      // TODO: Check all parties
      Candidate(
        { en: 'Henry Flynn', zh: '亨利 ‧ 飛靈' },
        PARTY.NPP,
        {
          en: 'Security Specialist',
          zh: '保安專業人員',
          es: 'Especialista en seguridad',
          fil: 'Espesyalista sa Seguridad',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Keith Freedman', zh: '基思 ‧ 費里德曼' },
        PARTY.NPP,
        {
          en: 'Business Owner / Teacher',
          zh: '企業主 / 教師',
          es: 'Dueño de negocio / Maestro',
          fil: 'May-ari ng Negosyo/Guro',
        },
        'https://drive.google.com/file/d/1thAHWOZAizKi7YqFHqrvC6wlCzs49Y4K/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Dylan Hirsch-Shell', zh: '迪倫 ‧ 赫希-謝爾' },
        PARTY.NPP,
        {
          en: 'Engineer',
          zh: '工程師',
          es: 'Ingeniero',
          fil: 'Inihinyero',
        },
        'https://drive.google.com/file/d/1yY0VTgBXH6nzatvMP3PMEGPm9-EJWW6P/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Nelson Mei', zh: '梅燦斌 ' },
        PARTY.NPP,
        {
          en: 'Software Engineer',
          zh: '軟件工程師',
          es: 'Ingeniero de programas',
          fil: 'Inhinyero ng Software',
        },
        'https://drive.google.com/file/d/1u6H4FSNu5AylvDDt_sEstri-5ZwSS_gq/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Paul Ybarra Robertson', zh: '保羅 ‧ 伊巴拉 ‧ 羅伯森' },
        PARTY.NPP,
        {
          en: 'Self Employed',
          zh: '自僱人士',
          es: 'Empleado independiente',
          fil: 'Sarili ang Pagka-empleyo',
        },
        QS.NO_CONTACT
      ),
      Candidate(
        { en: 'Shahram Shariati', zh: '沙赫拉姆 ‧ 沙里亞提' },
        PARTY.NPP,
        {
          en: 'Civil / Transportation Engineer',
          zh: '民用 / 運輸工程師',
          es: 'Ingeniero civil y de transporte',
          fil: 'Inhinyerong Sibil / Pantransportasyon',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jon Ernest Soderstrom', zh: '約翰 ‧ 索德斯特螺慕' },
        PARTY.NPP,
        {
          en: 'None',
          zh: '沒有',
          es: 'Sin designación',
          fil: 'Wala',
        },
        QS.NO_RESPONSE
      ),
    ],
    defaultOrder,
    'Vote for all three, in any order',
    ENDORSEMENT_ORDER.RANDOM
  ),
  'Supervisor, District 1': Contest(
    JURISDICTION.LOCAL,
    {
      en: 'Supervisor, District 1',
      zh: '市議員，第1選區',
      es: 'Junta de Supervisores, Distrito 1',
      fil: 'Lupon ng mga Superbisor, Distrito 1',
    },
    [
      Candidate(
        { en: 'Jeremiah Boehner', zh: '耶利米 ‧ 博納' },
        PARTY.NP,
        {
          en: 'Entrepreneur',
          zh: '企業家',
          es: 'Emprendedor',
          fil: 'Negosyante',
        },
        'https://drive.google.com/file/d/1rom9gHJ-R1NBLBOd_yt6TXF62yaoBEf4/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Connie Chan', zh: '陳詩敏' },
        PARTY.NP,
        {
          en: 'District 1 Supervisor; Incumbent',
          zh: '第1選區市議員',
          es: 'Supervisora del Distrito 1',
          fil: 'Superbisor ng Distrito 1',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: "Sherman D'Silva", zh: '薛曼 ‧ 狄西瓦' },
        PARTY.NP,
        {
          en: 'Operations Manager',
          zh: '營運經理',
          es: 'Gerente de operaciones',
          fil: 'Tagapamahala ng mga Operasyon',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jen Nossokoff', zh: '珍 ‧ 諾索科夫' },
        PARTY.NP,
        {
          en: 'Physician Assistant',
          zh: '醫生助理',
          es: 'Assistente médico',
          fil: 'Katuwang ng Doktor',
        },
        'https://drive.google.com/file/d/1GAux9-4bU7L2knVeKtXUJVbf6ffGoIYH/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Marjan Philhour', zh: '邁珍' },
        PARTY.NP,
        {
          en: 'Neighborhood Business Owner',
          zh: '鄰里企業主',
          es: 'Dueña de negocio local',
          fil: 'May-ari ng Negosyo sa Komunudad',
        },
        'https://drive.google.com/file/d/1Sta2f9RNzfSQK9YKaahn9trJsBNxw6p3/view?usp=drive_link',
        true
      ),
    ],
    defaultOrder
  ),
  'Supervisor, District 3': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 3', zh: '市議員，第3選區' },
    [
      Candidate(
        { en: 'Wendy Ha Chau', zh: '周霞 ' },
        PARTY.NP,
        {
          en: 'Civil Rights Attorney',
          zh: '民權律師',
          es: 'Abogada de derecho civil',
          fil: 'Abugado para sa mga Karapatang Sibil',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Moe Jamil', zh: '麥 ‧ 讚明' },
        PARTY.NP,
        {
          en: 'Deputy City Attorney',
          zh: '副市府律師',
          es: 'Abogado de la Ciudad adjunto',
          fil: 'Katuwang ng Abugado ng Lungsod',
        },
        'https://drive.google.com/file/d/1kFpjMaIrqEeiMYqTzdTk1jjFccBqXWX1/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Sharon Lai', zh: '黎慧心' },
        PARTY.NP,
        {
          en: 'Director, Economic Non-profit',
          zh: '非營利經濟組織總監',
          es: 'Directora, organización económica sin fines de lucro',
          fil: 'Direktor ng Non-profit',
        },
        'https://drive.google.com/file/d/1Hzzho-0XCFEFmAjz981IamuOP3PQUSG3/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Eduard Navarro', zh: '愛德華 ‧ 納瓦羅' },
        PARTY.NP,
        {
          en: 'Urban Designer / Entrepreneur',
          zh: '城市設計師 / 企業家',
          es: 'Diseñador urbano / Emprendedor',
          fil: 'Taga-disenyo ng Lungsod / Negosyante',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Danny Sauter', zh: '李爾德' },
        PARTY.NP,
        {
          en: 'Neighborhood Center Director',
          zh: '鄰里中心總監',
          es: 'Director de centro local',
          fil: 'Direktor ng Sentro ng Komunidad',
        },
        'https://drive.google.com/file/d/1UMoyi4Bud7B6elaLsYDr4HC2lrUfIALV/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Matthew Susk', zh: '馬修 ‧ 蘇克' },
        PARTY.NP,
        {
          en: 'Businessman / Safety Director',
          zh: '商人 / 安全總監',
          es: 'Empresario / Director de seguridad',
          fil: 'Negosyante / Direktor para sa Kaligtasan',
        },
        'https://drive.google.com/file/d/1Tk5H3vGG6sds5HE_UNHkzUlD88L8wWsn/view?usp=drive_link'
      ),
    ],
    defaultOrder
  ),
  'Supervisor, District 5': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 5', zh: '市議員，第5選區' },
    [
      Candidate(
        { en: 'Allen Jones', zh: '艾倫 ‧ 鍾斯' },
        PARTY.NP,
        {
          en: 'Draftsman',
          zh: '繪圖員',
          es: 'Dibujante',
          fil: 'Dibuhante',
        },
        'https://drive.google.com/file/d/1fWLqpKwX7y9Ajyv02JgesjZ1h5v9SAQp/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Autumn Looijen', zh: '洛珍' },
        PARTY.NP,
        {
          en: 'Community Organizer',
          zh: '社區組織者',
          es: 'Organizadora comunitaria',
          fil: 'Tagapag-organisa sa Komunidad',
        },
        'https://drive.google.com/file/d/13Mbhn4vpsgIAfp0vCOAS5NQAdOMULfgk/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Bilal Mahmood', zh: '馬百樂' },
        PARTY.NP,
        {
          en: 'Climate Nonprofit Director',
          zh: '非營利氣候組織總監',
          es: 'Director de organización climática sin fines de lucro',
          fil: 'Direktor ng Nonprofit na para sa Klima',
        },
        'https://drive.google.com/file/d/1LpdT4oZJys1rExDyoFogm9ufJUx5aTCg/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Dean Preston', zh: '潘正義' },
        PARTY.NP,
        {
          en: 'District 5 Supervisor; Incumbent',
          zh: '第5選區市議員',
          es: 'Supervisor del Distrito 5',
          fil: 'Superbisor ng Distrito 5',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Scotty Jacobs', zh: '史葛特 ‧ 雅各布斯' },
        PARTY.NP,
        {
          en: 'Private Sector Businessperson',
          zh: '私營部門商人',
          es: 'Empresario de sector privado',
          fil: 'Negosyante para sa Pribadong Sektor',
        },
        'https://drive.google.com/file/d/1PiBzzhCa_FH0ta6sxUWoVKSQSbXnvCWV/view?usp=drive_link'
      ),
    ],
    defaultOrder
  ),
  'Supervisor, District 7': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 7', zh: '市議員，第7選區' },
    [
      Candidate(
        { en: 'Matt Boschetto', zh: '馬修 ‧ 博施基托' },
        PARTY.DEM,
        {
          en: 'Small Business Owner',
          zh: '小企業主',
          es: 'Dueño de pequeño negocio',
          fil: 'May-ari ng Maliit na Negosyo',
        },
        'https://drive.google.com/file/d/1aSrYI4cD3_LdAIxMsiL022QO0N2tNpg3/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Stephen Martin-Pinto', zh: '史蒂芬 ‧ 馬丁-品托' },
        PARTY.NP,
        {
          en: 'Firefighter / Military Reservist',
          zh: '消防員 / 預備役軍人',
          es: 'Bombero / Miembro de reserva del ejército',
          fil: 'Bumbero / Reserbista ng Militar',
        },
        'https://drive.google.com/file/d/1CkVZ6-xHH9pk0vV2mbds9OjQt9mFYQU6/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Myrna Melgar', zh: '梅義加' },
        PARTY.DEM,
        {
          en: 'District 7 Supervisor; Incumbent',
          zh: '第7選區市議員',
          es: 'Supervisora del Distrito 7',
          fil: 'Superbisor ng Distrito 7',
        },
        'https://drive.google.com/file/d/184bsB3KxQdCFUiuo6oSxczn375N057kp/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Edward Yee', zh: '余朝活' },
        PARTY.NP,
        {
          en: 'None',
          zh: '沒有',
          es: 'Sin designación',
          fil: 'Wala',
        },
        QS.NO_RESPONSE
      ),
    ],
    defaultOrder
  ),
  'Supervisor, District 9': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 9', zh: '市議員，第9選區' },
    [
      Candidate(
        { en: 'Julian E. Bermudez', zh: '朱利安 ‧ 貝爾穆德斯' },
        PARTY.NP,
        {
          en: 'Businessman / Appliance Technician',
          zh: '商人／電器技術員',
          es: 'Empresario/ Técnico en electrodomésticos',
          fil: 'Negosyante/ Technician para sa mga Kasangkapan',
        },
        'https://drive.google.com/file/d/1oAV4KYR7GVU4pFmzh_R_BW1uQfCWoclO/view?usp=drive_link'
      ),
      Candidate(
        { en: 'H. Brown', zh: 'H ‧ 布朗' },
        PARTY.NP,
        {
          en: 'Retired Teacher',
          zh: '退休教師',
          es: 'Maestro jubilado',
          fil: 'Retiradong Guro',
        },
        'https://drive.google.com/file/d/1anDrTyzP13mA36aZiQ01G98KwQDRfMHy/view'
      ),
      Candidate(
        { en: 'Trevor L. Chandler', zh: '陳澤維' },
        PARTY.NP,
        {
          en: 'Public School Teacher',
          zh: '公立學校教師',
          es: 'Maestro de escuela pública',
          fil: 'Guro sa Pampublikong Paaralan',
        },
        'https://drive.google.com/file/d/12N-cKDUQtqz8jFSgjG8w_OrjXus1Txau/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Jackie Fielder', zh: '傑基 ‧ 菲爾德' },
        PARTY.NP,
        {
          en: 'Director, Climate Nonprofit',
          zh: '非營利氣候組織總監',
          es: 'Directora, organización climática sin fines de lucro',
          fil: 'Direktor, Non-profit na para sa Klima',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jaime Gutierrez', zh: '傑米 ‧ 古鐵雷斯 ' },
        PARTY.NP,
        {
          en: 'Transit Supervisor',
          zh: '運輸主管',
          es: 'Supervisor de tránsito',
          fil: 'Superbisor ng Pampublikong Transportasyon',
        },
        'https://drive.google.com/file/d/1KQDyh1oIolzh78S56pWK5Sct_QvGDMex/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Roberto Y. Hernandez', zh: '羅伯托 ‧ 赫南德斯' },
        PARTY.NP,
        {
          en: 'Nonprofit Executive Director',
          zh: '非營利組織行政總監',
          es: 'Director ejecutivo de organización sin fines de lucro',
          fil: 'Ehekutibong Direktor ng Nonprofit',
        },
        QS.DECLINED
      ),
      Candidate(
        { en: 'Stephen Torres', zh: '杜世衡' },
        PARTY.NP,
        {
          en: 'Hospitality Worker / Journalist',
          zh: '款待業工作者 / 記者',
          es: 'Trabajador hotelero / Periodista',
          fil: 'Nagtatrabaho sa Industriya ng Hospitalidad / Mamamahayag',
        },
        QS.NO_RESPONSE
      ),
    ],
    defaultOrder
  ),
  'Supervisor, District 11': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 11', zh: '市議員，第11選區' },
    [
      Candidate(
        { en: 'Chyanne Chen', zh: '陳小焱' },
        PARTY.NP,
        {
          en: 'Parent / Non-profit Director',
          zh: '家長 / 非營利組織總監',
          es: 'Madre / Directora de organización sin fines de lucro',
          fil: 'Magulang/Direktor ng Non-Profit',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Adlah Chisti', zh: '雅德拉 ‧ 克里斯蒂' },
        PARTY.NP,
        {
          en: 'Policy Analyst / Planner',
          zh: '政策分析師 / 規劃師',
          es: 'Analista política / Planificadora',
          fil: 'Tagasuri ng Polisiya / Tagaplano',
        },
        'https://drive.google.com/file/d/1Ps_fgQttujs1Rqyc2NJasNMsU-xalzA5/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Oscar Flores', zh: '奧斯卡 ‧ 弗洛雷斯' },
        PARTY.NP,
        {
          en: 'Project Engineer',
          zh: '項目工程師',
          es: 'Ingeniero de proyecto',
          fil: 'Inhinyero ng Proyekto',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Ernest Jones', zh: '恩內斯特 ‧ "EJ" ‧ 鍾斯' },
        PARTY.NP,
        {
          en: 'Community Organizer',
          zh: '社區組織者',
          es: 'Organizador comunitario',
          fil: 'Tagapag-organisa sa Komunudad',
        },
        'https://drive.google.com/file/d/1vUUy8Rf804k6Aoh44U5Fc8MvpVASNDgv/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Michael Lai', zh: '賴天宸' },
        PARTY.NP,
        {
          en: 'Early Education Director',
          zh: '早期教育總監',
          es: 'Director de educación temprana',
          fil: 'Direktor ng Maagang Edukasyon',
        },
        'https://drive.google.com/file/d/1uVkKjcI8Rxe06Bnnc-rVV2Ahr6w5WxnV/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Roger Marenco', zh: '羅傑 ‧ K ‧ 馬倫科' },
        PARTY.NP,
        {
          en: 'Organizer / Transit Operator',
          zh: '組織者 / 運輸操作員',
          es: 'Organizador / Operador de tránsito',
          fil: 'Organisador / Nagpapatakbo sa Pampublikong Transportasyon',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jose Morales', zh: '何西 ‧ 莫拉雷斯' },
        PARTY.NP,
        {
          en: 'None',
          zh: '沒有',
          es: 'Sin designación',
          fil: 'Wala',
        },
        'https://drive.google.com/file/d/1h7obFQKulp_wO9DOCLhmYC4P-A87qDe0/view?usp=drive_link'
      ),
    ],
    defaultOrder
  ),
  'City Attorney': Contest(
    JURISDICTION.LOCAL,
    { en: 'City Attorney', zh: '市府律師', es: 'Abogado de la Ciudad', fil: 'Abugado ng Lungsod' },
    [
      Candidate(
        { en: 'David Chiu', zh: '邱信福' },
        PARTY.NP,
        {
          en: 'City Attorney',
          zh: '市府律師',
          es: 'Abogado de la Ciudad',
          fil: 'Abugado ng Lungsod',
        },
        QS.NOT_SENT,
        true
      ),
      Candidate(
        { en: 'Richard Woon', zh: '温健全' },
        PARTY.NP,
        {
          en: 'Attorney / Football Coach',
          zh: '律師 / 美式足球教練',
          es: 'Abogado / Entrenador de fútbol americano',
          fil: 'Abugado / Tagasanay sa Football',
        },
        QS.NOT_SENT
      ),
    ],
    defaultOrder
  ),
  'District Attorney': Contest(
    JURISDICTION.LOCAL,
    {
      en: 'District Attorney',
      zh: '地方檢察官',
      es: 'Fiscal de Distrito',
      fil: 'Abugado ng Distrito',
    },
    [
      Candidate(
        { en: 'Brooke Jenkins', zh: '謝安宜' },
        PARTY.DEM,
        {
          en: 'District Attorney',
          zh: '地方檢察官',
          es: 'Fiscal de Distrito',
          fil: 'Abugado ng Distrito',
        },
        'https://drive.google.com/file/d/1Oy1GZt3TplEJapQ-wfquBM9Wp_ktv7Oe/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Ryan Khojasteh', zh: '藍可嘉' },
        PARTY.DEM,
        {
          en: 'Deputy District Attorney',
          zh: '副地方檢察官',
          es: 'Fiscal de Distrito Adjunto',
          fil: 'Katuwang ng Abugado ng Distrito',
        },
        QS.NO_RESPONSE
      ),
    ],
    defaultOrder
  ),
  Sheriff: Contest(
    JURISDICTION.LOCAL,
    { en: 'Sheriff', zh: '三藩市縣警長', es: 'Alguacil', fil: 'Sheriff' },
    [
      Candidate(
        { en: 'Paul Miyamoto', zh: '保羅 ‧ 宮本' },
        PARTY.NP,
        {
          en: 'San Francisco Sheriff',
          zh: '三藩市縣警長',
          es: 'Alguacil de San Francisco',
          fil: 'Sheriff ng San Francisco',
        },
        QS.NOT_SENT,
        true
      ),
      Candidate(
        { en: 'Michael Juan', zh: '米高 ‧ 胡安' },
        PARTY.NP,
        { en: 'Police Officer', zh: '警察', es: 'Agente de policía', fil: 'Opisyal ng Pulisya' },
        QS.NOT_SENT
      ),
    ],
    defaultOrder
  ),
  Treasurer: Contest(
    JURISDICTION.LOCAL,
    { en: 'Treasurer', zh: '財政官', es: 'Tesorero', fil: 'Tesorero' },
    [
      Candidate(
        { en: 'José Cisneros', zh: '何世豪' },
        PARTY.NP,
        { en: 'Treasurer', zh: '財政官', es: 'Tesorero', fil: 'Tesorero' },
        QS.NOT_SENT,
        true
      ),
    ],
    defaultOrder
  ),
  'Proposition 2': Measure(
    JURISDICTION.STATE,
    { en: 'School facilities bond', zh: '學校設施債券' },
    '2',
    ENDORSEMENT.YES,
    'Proposition 2 (November 2024) would issue a bond to fund construction and improvement of K-12 school facilities.',
    'Fund construction and improvement of K-12 school facilities.'
  ),
  'Proposition 3': Measure(
    JURISDICTION.STATE,
    { en: 'Marriage equality', zh: '婚姻平權' },
    '3',
    ENDORSEMENT.YES,
    'Proposition 3 (November 2024) would repeal Proposition 8 and establish a right to marry in the California Constitution.',
    'Repeal Proposition 8 and establish a right to marry in the California Constitution.'
  ),
  'Proposition 4': Measure(
    JURISDICTION.STATE,
    {
      en: 'Safe Drinking Water, Wildfire Prevention, Drought Preparedness, and Clean Air Bond',
      zh: '安全飲用水、野火預防、乾旱防備及清潔空氣債券',
    },
    '4',
    ENDORSEMENT.YES,
    'Proposition 4 (November 2024) would issue a bond to fund water infrastructure, wildfire prevention, and drought preparedness.',
    'Fund water infrastructure, wildfire prevention, and drought preparedness.'
  ),
  'Proposition 5': Measure(
    JURISDICTION.STATE,
    {
      en: 'Allows Local Bonds for Affordable Housing and Public Infrastructure With 55% Voter Approval',
      zh: '允許在 55% 選民批准的情況下為經濟適用房和公共基礎設施發行地方債券',
    },
    '5',
    ENDORSEMENT.YES,
    'Proposition 5 (November 2024) would lower the vote threshold from 66.67% to 55% for local bond measures to fund housing projects and public infrastructure.',
    'Lower the vote threshold from 66.67% to 55% for local bond measures to fund housing projects and public infrastructure.'
  ),
  'Proposition 6': Measure(
    JURISDICTION.STATE,
    { en: 'End slavery', zh: '結束奴隸制' },
    '6',
    ENDORSEMENT.YES,
    'Proposition 6 (November 2024) would remove forced labor as punishment for a crime from the California constitution.',
    'Ban forced labor in prisons.'
  ),
  'Proposition 32': Measure(
    JURISDICTION.STATE,
    { en: 'Raise Minimum Wage', zh: '提高最低工資' },
    '32',
    ENDORSEMENT.YES,
    'Proposition 32 (November 2024) would increase California minimum wage from $15 to $18 an hour.',
    'Raise California minimum wage from $15 to $18 an hour.'
  ),
  'Proposition 33': Measure(
    JURISDICTION.STATE,
    { en: 'Repeal State Regulations on Rent Control', zh: '廢除加州租金管制法規' },
    '33',
    ENDORSEMENT.NO,
    'Proposition 33 (November 2024) would repeal the Costa Hawkins Rental Housing Act.',
    'Lets cities set rent control laws without oversight from state regulators.'
  ),
  'Proposition 34': Measure(
    JURISDICTION.STATE,
    {
      en: 'Restricts Spending by Health Care Providers Meeting Specified Criteria',
      zh: '限制醫療保健提供者滿足特定標準的支出',
    },
    '34',
    ENDORSEMENT.YES,
    'Proposition 34 (November 2024) would require health care providers to spend 98% of revenues from federal discount prescription drug program on direct patient care.',
    'Require health care providers to spend 98% of government money on patients, not political campaigns.'
  ),
  'Proposition 35': Measure(
    JURISDICTION.STATE,
    {
      en: 'Provides Permanent Funding for Medi-Cal Health Care Services',
      zh: '為 Medi-Cal 醫療保健服務提供永久資金',
    },
    '35',
    ENDORSEMENT.YES,
    'Proposition 35 (November 2024) would permanently authorize a tax on managed care organizations to fund Medi-Cal programs.',
    'Permanently authorize a tax on managed care organizations to fund Medi-Cal programs.'
  ),
  'Proposition 36': Measure(
    JURISDICTION.STATE,
    {
      en: 'Allows Felony Charges and Increases Sentences for Certain Drug and Theft Crimes',
      zh: '允許對某些毒品和盜竊犯罪提出重罪指控並加重刑罰',
    },
    '36',
    ENDORSEMENT.YES,
    'Proposition 36 (November 2024) would reform Proposition 47 to increase drug crime and theft penalties and allow a new class of crime to be called treatment-mandated felony, which gives the offender the option to participate in drug and mental health treatment.',
    'Reform Prop 47; increase penalties for repeat shoplifting and fentanyl dealing.'
  ),
  'Proposition A': Measure(
    JURISDICTION.LOCAL,
    { en: 'Schools Improvement and Safety Bond', zh: '學校改善與安全債券' },
    'A',
    ENDORSEMENT.YES,
    'Proposition A (November 2024) would issue a bond to fund improvements to SF public schools and create a new food distribution hub for elementary schools.',
    'Fund improvements to San Francisco public schools, and build a new central kitchen for elementary schools.'
  ),
  'Proposition B': Measure(
    JURISDICTION.LOCAL,
    { en: 'Healthcare, Streets, and Shelters Bond', zh: '醫療保健、街道和避難所債券' },
    'B',
    ENDORSEMENT.YES,
    'Proposition B (November 2024) would issue a $390 million bond to retrofit and renovate existing public health infrastructure, fund street safety, and improve and modernize public spaces downtown.',
    'Fund improvements and seismic upgrades to SF General Hospital, Laguna Honda, and community health centers.'
  ),
  'Proposition C': Measure(
    JURISDICTION.LOCAL,
    { en: 'Inspector General', zh: '監察長' },
    'C',
    ENDORSEMENT.NO,
    "Proposition C (November 2024) would create a new Inspector General in the City Controller's office which would dupliate investigatory roles already assigned to the Ethics Commission, District Attorney, City Attorney, City Services Auditor, and the Sherif's Office of Inspector General.",
    "Duplicates existing authority already held by the Ethics Commission, District Attorney, City Attorney, City Services Auditor, and the Sherif's Office of Inspector General."
  ),
  'Proposition D': Measure(
    JURISDICTION.LOCAL,
    { en: 'City Commission Reform', zh: '市委會改革' },
    'D',
    ENDORSEMENT.YES,
    'Proposition D (November 2024) would issue a bond to fund improvements to SF public schools and create a new food distribution hub for elementary schools.',
    'Streamlines City Hall bureaucracy; reduces commissions from 130 to 65; strengthens Mayoral oversight of departments.'
  ),
  'Proposition E': Measure(
    JURISDICTION.LOCAL,
    { en: 'Fake Commission Reform', zh: '假市委會改革' },
    'E',
    ENDORSEMENT.NO,
    'Proposition E (November 2024) would creates a new unelected commission to study why San Francisco has 130 commissions. It does not make any reforms unless the commission recommends them.',
    'Creates a new unelected commission to study why we have 130 commissions. Does not make any reforms unless the commission recommends them.'
  ),
  'Proposition F': Measure(
    JURISDICTION.LOCAL,
    { en: 'Deferred Retirement for SF Police', zh: '三藩市警察延遲退休' },
    'F',
    ENDORSEMENT.YES,
    'Proposition F (November 2024) would let officers near retirement postpone their retirement for up to five years in exchange for extra compensation.',
    'Shores up police staffing by letting officers defer retirement for up to five years.'
  ),
  'Proposition G': Measure(
    JURISDICTION.LOCAL,
    { en: 'Budget lock-in for certain housing subsidies', zh: '住房補貼重複支出' },
    'G',
    ENDORSEMENT.NO,
    'Proposition G (November 2024) would allocate an extra $8 million to $14 million to certain housing subsidy programs.',
    'Mandates deficit spending on some housing subsidies.'
  ),
  'Proposition H': Measure(
    JURISDICTION.LOCAL,
    { en: 'Expanded retirement benefits for firefighters', zh: '擴大消防員的退休福利' },
    'H',
    ENDORSEMENT.YES,
    'Proposition H (November 2024) would set the retirement age for Firefighters hired after 2012 to 55 years old.',
    'Sets retirement age for Firefighters hired after 2012 to 55 years old.'
  ),
  'Proposition I': Measure(
    JURISDICTION.LOCAL,
    { en: 'Nurses Retirement Credit', zh: '護理員退休信貸' },
    'I',
    ENDORSEMENT.YES,
    'Proposition I (November 2024) would allow nurses converting to full-time employees to buy credit in the retirement system for time worked.',
    'Allow nurses converting to full-time employees to buy credit in the retirement system for time worked.'
  ),
  'Proposition J': Measure(
    JURISDICTION.LOCAL,
    { en: 'Student Success Fund accountability', zh: '學生成功基金的責任' },
    'J',
    ENDORSEMENT.YES,
    'Proposition J (November 2024) would ensure the many millions of dollars the city spends on children and youth services is spent in a coordinated way.',
    'Ensure the many millions of dollars the city spends on children and youth services is spent in a coordinated way.'
  ),
  'Proposition K': Measure(
    JURISDICTION.LOCAL,
    { en: 'Permanently close part of Great Highway', zh: '永久關閉部分Great Highway' },
    'K',
    ENDORSEMENT.YES,
    'Proposition K (November 2024) would close a section of the Upper Great Highway between Lincoln and Sloat, with the intention to convert to an oceanside park.',
    'Close a section of the Upper Great Highway between Lincoln and Sloat, with the intention to convert to an oceanside park.'
  ),
  'Proposition L': Measure(
    JURISDICTION.LOCAL,
    { en: 'Tax riders of Uber, Lyft, and Waymo', zh: 'Uber、Lyft 和 Waymo 乘客的納稅' },
    'L',
    ENDORSEMENT.NO,
    'Proposition L (November 2024) would establish a new permanent tax for a temporary budget deficit. Can only be spent on salaries & operations, not network improvements.',
    'Permanent new tax for temporary budget deficit. Can only be spent on salaries & operations, not network improvements.'
  ),
  'Proposition M': Measure(
    JURISDICTION.LOCAL,
    { en: 'Business Tax Reform', zh: '營業稅改革' },
    'M',
    ENDORSEMENT.YES,
    'Proposition M (November 2024) would simplify business taxes, lowering some while raising others; help existing small businesses; attract new businesses and startups; and stabilize tax revenues.',
    'Simplify business taxes, lowering some while raising others. Help existing small businesses, attract new businesses and startups, and stabilize tax revenues.'
  ),
  'Proposition N': Measure(
    JURISDICTION.LOCAL,
    { en: 'First responder student loan forgiveness program', zh: '急救人員的學生貸款減免計劃' },
    'N',
    ENDORSEMENT.YES,
    'Proposition N (November 2024) would forgive up to $25,000 in student loans and job-related education expenses for new first responders.',
    'Forgive up to $25,000 in student loans and job-related education expenses for new first responders.'
  ),
  'Proposition O': Measure(
    JURISDICTION.LOCAL,
    { en: 'Reproductive Freedom', zh: '生育自由' },
    'O',
    ENDORSEMENT.YES,
    'Proposition O (November 2024) would make it City policy to not cooperate with out-of-state investigations related to abortions. And ensure safe and legal access to abortion.',
    'Set City policy to not cooperate with out-of-state investigations related to abortions. Ensure safe and legal access to abortion.'
  ),
};
